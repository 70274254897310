<template>
    <div class="pt-20">
        <!-- Branding -->
        <div class="container mx-auto mb-12">
            <div class="flex items-center justify-center">
                <svg class="text-green-600 h-16 w-16 mr-2" width="120" height="120" focusable="false" role="img"
                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                    <path fill="currentColor"
                          d="M546.2 9.7c-5.6-12.5-21.6-13-28.3-1.2C486.9 62.4 431.4 96 368 96h-80C182 96 96 182 96 288c0 7 .8 13.7 1.5 20.5C161.3 262.8 253.4 224 384 224c8.8 0 16 7.2 16 16s-7.2 16-16 16C132.6 256 26 410.1 2.4 468c-6.6 16.3 1.2 34.9 17.5 41.6 16.4 6.8 35-1.1 41.8-17.3 1.5-3.6 20.9-47.9 71.9-90.6 32.4 43.9 94 85.8 174.9 77.2C465.5 467.5 576 326.7 576 154.3c0-50.2-10.8-102.2-29.8-144.6z"></path>
                </svg>
                <span class="font-semibold text-4xl tracking-tight">
                Project Tea Leaf
            </span>
            </div>
        </div>

        <!-- Container -->
        <div class="container mx-auto">
            <div class="flex justify-center flex-column">
                <!-- Row -->
                <div class="w-full xl:w-3/4 lg:w-11/12 flex shadow">
                    <!-- Col -->
                    <div
                            class="w-full h-auto bg-gray-400 hidden lg:block lg:w-1/2 bg-cover rounded-l-lg"
                            style="background-image: url('https://source.unsplash.com/K4mSJ7kc0As/600x800')">
                    </div>
                    <!-- Col -->
                    <div class="w-full lg:w-1/2 bg-white p-5 rounded-lg lg:rounded-l-none">
                        <h3 class="pt-4 text-2xl text-center">
                            Welcome Back
                        </h3>

                        <div class="px-8" v-if="loginErrors.message">
                            <div class="flex items-center bg-red-500 text-white text-sm font-bold px-4 py-3 mt-4"
                                 role="alert">
                                <p>{{ loginErrors.message }}</p>
                            </div>
                        </div>

                        <form class="px-8 pt-6 pb-8 mb-4 bg-white rounded" @submit.prevent="login">
                            <div class="mb-4">
                                <label class="block mb-2 text-sm font-bold text-gray-700" for="email">
                                    E-mail Address
                                </label>
                                <input
                                        class="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                        :class="{'mb-3 border-red-500': loginErrors.errors && loginErrors.errors.email }"
                                        id="email"
                                        type="text"
                                        placeholder="rufus@goodboyaccounting.com"
                                        v-model="form.email"
                                        required
                                        autocomplete="email"
                                />

                                <p class="text-xs italic text-red-500"
                                   v-if="loginErrors.errors && loginErrors.errors.email">
                                    {{ loginErrors.errors.email[0] }}
                                </p>
                            </div>
                            <div class="mb-4">
                                <label class="block mb-2 text-sm font-bold text-gray-700" for="password">
                                    Password
                                </label>
                                <input
                                        class="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                        :class="{'mb-3 border-red-500': loginErrors.errors && loginErrors.errors.password }"
                                        id="password"
                                        type="password"
                                        v-model="form.password"
                                        placeholder=""
                                        autocomplete="current-password"
                                />

                                <p class="text-xs italic text-red-500"
                                   v-if="loginErrors.errors && loginErrors.errors.password">
                                    {{ loginErrors.errors.password[0] }}
                                </p>
                            </div>
                            <div class="mb-4">
                                <input
                                        class="mr-2 leading-tight"
                                        type="checkbox"
                                        name="remember"
                                        id="remember"
                                        v-model="form.remember"/>
                                <label class="text-sm" for="remember">
                                    Remember Me
                                </label>
                            </div>
                            <div class="mb-6 text-center">
                                <button type="submit"
                                        class="w-full flex justify-center items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150"
                                        :class="{'cursor-not-allowed': isLoading}"
                                        :disabled="isLoading"
                                        @click.prevent="login">

                                    <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                         v-if="isLoading">
                                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                                stroke-width="4"></circle>
                                        <path class="opacity-75" fill="currentColor"
                                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>

                                    <span>Log In</span>
                                </button>
                            </div>
                            <hr class="mb-6 border-t"/>
                            <div class="text-center">
                                <a
                                        class="inline-block text-sm text-blue-500 align-baseline hover:text-blue-800"
                                        href="#">
                                    Register a free account
                                </a>
                            </div>

                            <div class="text-center">
                                <a
                                        class="inline-block text-sm text-blue-500 align-baseline hover:text-blue-800"
                                        href="#">
                                    Forgot Your Password?
                                </a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters, mapState} from 'vuex';

    export default {
        name: "Login",

        data() {
            return {
                form: {
                    email: null,
                    password: null,
                    remember: false,
                },
            }
        },

        created() {
            let self = this;

            this.$watch('isLoggedIn', (newValue, oldValue) => {
                if (newValue === true) {
                    this.redirectIfLoggedIn();
                }
            });
        },

        mounted() {
            if (this.isLoggedIn) {
                this.redirectIfLoggedIn();
            }

            this.$store.dispatch('authentication/checkLogin');
        },

        methods: {
            redirectIfLoggedIn() {
                if (this.$route.query.next) {
                    this.$router.push(this.$route.query.next)
                }

                this.$router.push({
                    name: 'Dashboard'
                })
            },

            login() {
                this.$store.dispatch('authentication/login', this.form);
            }
        },

        computed: {
            ...mapGetters({
                isLoading: 'authentication/isLoading',
                isLoggedIn: 'authentication/isLoggedIn'
            }),

            loginErrors() {
                return this.$store.state.authentication.loginErrors;
            },
        }
    }
</script>

<style scoped>

</style>